import { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import axios from 'axios';
import { URL } from '../constants/index';

const user = JSON.parse(localStorage.getItem('authenticatedUser'));

const initialState = {
  bookings: [],
  latestBookings: [],
  admins: [],
  stats: [],
  auth: user ? true : false,
  loading: true,
  error: null,
  user: user,
  message: null
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  //actions
  //@desc Get booking statistics
  async function getStats(user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };

      const res = await axios.get(`${URL}api/bookings/stats`, config);

      dispatch({ type: 'GET_STATS', payload: res.data.stats });
    } catch (error) {
      let payload;
      let err = error.response?.data.error;
      if (err === 'jwt expired') {
        payload = 'Your session has expired please login';
      } else {
        payload = error.response?.data.error;
      }
      dispatch({
        type: 'GET_STATS_ERROR',
        payload
      });
    }
  }

  //@desc Register user action
  async function addNewUser(formData, user) {
    const { name, email, phone, permission } = formData;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      await axios.post(
        `${URL}api/auth/register`,
        {
          name,
          email,
          phone,
          permission
        },
        config
      );
      dispatch({ type: 'ADD_USER' });
    } catch (error) {
      dispatch({
        type: 'ADD_USER_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc get all users (Admins)
  async function getAdmins(user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.get(`${URL}api/auth/admins`, config);
      dispatch({ type: 'GET_ADMINS', payload: res?.data?.users });
    } catch (error) {
      let payload;
      let err = error.response?.data?.error;
      if (err === 'jwt expired') {
        payload = 'Your session has expired please login';
      } else {
        payload = error.response?.data.error;
      }
      dispatch({
        type: 'GET_ADMINS_ERROR',
        payload
      });
    }
  }

  //@desc Update Admin details
  async function updateAdmin(user, formData) {
    const { _id: id, permission } = formData;
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.put(
        `${URL}api/auth/admins/${id}`,
        { permission },
        config
      );
      dispatch({ type: 'UPDATE_ADMIN', payload: res.data.updatedUser });
    } catch (error) {
      dispatch({
        type: 'UPDATE_ADMIN_ERROR',
        payload: error?.response?.data.error
      });
    }
  }

  //@desc Finish admin account setup
  async function finishSetup(formData, params) {
    const { password, passwordConfirmation } = formData;
    const { inviteToken } = params;
    try {
      const res = await axios.put(`${URL}api/auth/newaccount/${inviteToken}`, {
        password,
        passwordConfirmation
      });
      dispatch({ type: 'FINISH_ADMIN_SETUP', payload: res.data });
    } catch (error) {
      dispatch({
        type: 'FINISH_ADMIN_SETUP_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc login user
  async function logInUser({ email, password }) {
    try {
      const res = await axios.post(`${URL}api/auth/login`, {
        email,
        password
      });
      dispatch({ type: 'LOGIN_USER', payload: res.data });
    } catch (error) {
      dispatch({
        type: 'LOGIN_USER_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc password reset-link request
  async function fogortPassword({ email }) {
    try {
      const res = await axios.post(`${URL}api/auth/forgotpassword`, {
        email
      });
      dispatch({ type: 'FORGOT_PASSWORD', payload: res.data.message });
    } catch (error) {
      dispatch({
        type: 'FORGOT_PASSWORD_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc Reset password
  async function resetPassword(formData, params) {
    const { password, passwordConfirmation } = formData;
    const { resetToken } = params;
    try {
      const res = await axios.put(
        `${URL}api/auth/resetpassword/${resetToken}`,
        {
          password,
          passwordConfirmation
        }
      );
      dispatch({ type: 'PASSWORD_RESET', payload: res.data.message });
    } catch (error) {
      dispatch({
        type: 'PASSWORD_RESET_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc logout user
  function logOutUser() {
    dispatch({ type: 'LOG_OUT_USER' });
  }

  //@desc get all bookings
  async function getBookings(user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.get(`${URL}api/bookings`, config);

      dispatch({ type: 'GET_BOOKINGS', payload: res.data.bookings });
    } catch (error) {
      let payload;
      let err = error.response?.data.error;
      if (err === 'jwt expired') {
        payload = 'Your session has expired please login';
      } else {
        payload = error.response?.data.error;
      }
      dispatch({
        type: 'GET_BOOKINGS_ERROR',
        payload
      });
    }
  }

  //@desc get latest bookings
  async function getLatestBookings(user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.get(`${URL}api/bookings/latest`, config);

      dispatch({ type: 'GET_LATEST_BOOKINGS', payload: res.data.bookings });
    } catch (error) {
      let payload;
      let err = error.response?.data.error;
      if (err === 'jwt expired') {
        payload = 'Your session has expired please login';
      } else {
        payload = error.response?.data.error;
      }
      dispatch({
        type: 'GET_LATEST_BOOKINGS_ERROR',
        payload
      });
    }
  }

  //@desc Delete Booking
  async function deleteBooking(id, user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.delete(`${URL}api/bookings/${id}`, config);

      dispatch({ type: 'DELETE_BOOKING', payload: res.data.deletedBooking });
    } catch (error) {
      dispatch({
        type: 'DELETE_BOOKING_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc Delete Admin
  async function deleteAdmin(id, user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.delete(`${URL}api/auth/admins/${id}`, config);

      dispatch({ type: 'DELETE_ADMIN', payload: res.data.deletedUser });
    } catch (error) {
      dispatch({
        type: 'DELETE_ADMIN_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc Update Booking
  async function updateBooking(formData, user) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };
      const res = await axios.put(
        `${URL}api/bookings/${formData._id}`,
        formData,
        config
      );

      dispatch({ type: 'UPDATE_BOOKING', payload: res.data.booking });
    } catch (error) {
      dispatch({
        type: 'UPDATE_BOOKING_ERROR',
        payload: error.response?.data.error
      });
    }
  }

  //@desc Adding Booking action
  // function addBooking(booking) {
  //   dispatch({ type: 'ADD_BOOKING', payload: booking });
  // }

  return (
    <GlobalContext.Provider
      value={{
        bookings: state.bookings,
        auth: state.auth,
        error: state.error,
        loading: state.loading,
        user: state.user,
        admins: state.admins,
        message: state.message,
        stats: state.stats,
        latestBookings: state.latestBookings,
        deleteBooking,
        updateBooking,
        getBookings,
        logInUser,
        logOutUser,
        addNewUser,
        finishSetup,
        fogortPassword,
        resetPassword,
        getAdmins,
        updateAdmin,
        deleteAdmin,
        getStats,
        getLatestBookings
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
