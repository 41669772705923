export default (state, action) => {
  switch (action.type) {
    case 'GET_BOOKINGS':
      return {
        ...state,
        loading: false,
        bookings: action.payload
      };
    case 'GET_LATEST_BOOKINGS':
      return {
        ...state,
        loading: false,
        latestBookings: action.payload
      };
    case 'GET_STATS':
      return {
        ...state,
        stats: action.payload
      };
    case 'GET_ADMINS':
      return {
        ...state,
        loading: false,
        admins: action.payload
      };
    case 'UPDATE_ADMIN':
      return {
        ...state,
        admins: state.admins.map((admin) =>
          admin._id === action.payload._id ? action.payload : admin
        )
      };
    case 'DELETE_BOOKING':
      return {
        ...state,
        bookings: state.bookings.filter(
          (booking) => booking._id !== action.payload._id
        )
      };
    case 'DELETE_ADMIN':
      return {
        ...state,
        admins: state.admins.filter((admin) => admin._id !== action.payload._id)
      };
    case 'UPDATE_BOOKING':
      return {
        ...state,
        bookings: state.bookings.map((booking) =>
          booking._id === action.payload._id ? action.payload : booking
        )
      };
    case 'FINISH_ADMIN_SETUP':
    case 'LOGIN_USER':
      localStorage.setItem(
        'authenticatedUser',
        JSON.stringify({ ...action?.payload })
      );
      return {
        ...state,
        user: action.payload,
        auth: true,
        error: null
      };
    case 'LOG_OUT_USER':
      localStorage.clear();
      return {
        ...state,
        user: null,
        auth: false,
        bookings: [],
        admins: [],
        stats: []
      };
    case 'PASSWORD_RESET':
      return {
        ...state,
        error: null,
        message: action.payload
      };
    case 'FORGOT_PASSWORD':
      return {
        ...state,
        error: null,
        message: action.payload
      };
    // case 'ADD_BOOKING':
    //   return {
    //     ...state,
    //     bookings: [...state.bookings, action.payload]
    //   };
    case 'FORGOT_PASSWORD_ERROR':
    case 'PASSWORD_RESET_ERROR':
    case 'LOGIN_USER_ERROR':
    case 'UPDATE_ADMIN_ERROR':
    case 'UPDATE_BOOKING_ERROR':
    case 'DELETE_ADMIN_ERROR':
    case 'GET_ADMINS_ERROR':
    case 'ADD_USER_ERROR':
    case 'GET_LATEST_BOOKINGS_ERROR':
    case 'GET_BOOKINGS_ERROR':
    case 'GET_STATS_ERROR':
    case 'DELETE_BOOKING_ERROR':
    case 'FINISH_ADMIN_SETUP_ERROR':
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};
